import { useEffect, useState } from "react";
import { coinageRead } from "../useCoinageContract";
import { coinageTrialRead } from "../useCoinageTrialContract";
import { useAccount, useContractReads } from "wagmi";

export const useIsHolder = (): {
  isHolder: boolean;
  isWalletConnected: boolean;
  address: `0x${string}` | undefined;
} => {
  const [isHolder, setIsHolder] = useState(false);
  const { address, isDisconnected } = useAccount();

  const { data: membershipData } = useContractReads({
    contracts: [
      // @ts-expect-error  Types of property 'abi' are incompatible.
      {
        ...coinageRead,
        functionName: "balanceOfBatch",
        args: [
          [address, address, address],
          [1, 2, 3]
        ]
      },
      // @ts-expect-error  Types of property 'abi' are incompatible.
      {
        ...coinageTrialRead,
        functionName: "balanceOf",
        args: [address!]
      }
    ],
    enabled: !!address
  });

  useEffect(() => {
    if (isDisconnected) {
      return setIsHolder(false);
    } else {
      let isHolder = false;
      if (membershipData?.length == 2) {
        const [tokenCountData, trialBalanceData] = membershipData;
        if (trialBalanceData?.status === "success") {
          const validTokens = trialBalanceData.result as number;
          isHolder = validTokens > 0;
        }
        if (tokenCountData?.status === "success") {
          const tokens = tokenCountData.result as number[];
          const hasTokens = tokens?.some(token => token > 0);
          isHolder = isHolder || hasTokens;
        }
      }
      setIsHolder(isHolder);
    }
  }, [membershipData, isDisconnected]);

  return { isHolder, isWalletConnected: !isDisconnected, address };
};
